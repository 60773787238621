import { useLocation, redirectTo } from '@reach/router';
import type React from 'react';
import { useEffect } from 'react';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { RegistrationForm } from '@xing-com/crate-login-account-packages';
import { BackgroundLayout } from '@xing-com/crate-login-layout';
import { useLoginTracking } from '@xing-com/crate-login-tracking';
import { getMainHost, getExtraParams } from '@xing-com/crate-login-utils';
import { useHost, useLoginAppUrl } from '@xing-com/crate-xinglet';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import { useLoginState } from '@xing-com/hub';

export const Registration: React.FC = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { trackPageView } = useLoginTracking();
  const { getSearchParameter } = useSearchParameter();
  const isLoggedIn = useLoginState() === 'LOGGED_IN';
  const extraParams = getExtraParams(urlParams);

  const host = useHost();
  const { isRecurringUser } = useRuntimeConfig();

  let signupChannel = getSearchParameter('signup_channel');
  // we want to actively distinguish between registrations
  // being made on the login page and on the registration page (e.g. via oauth)
  // hence we need to overwrite those special fallback channels
  if (!signupChannel || signupChannel === 'minireg_loginpage') {
    signupChannel = 'minireg_fullpage';
  }

  const loginUrl =
    useLoginAppUrl() + `?signup_channel=${signupChannel}${extraParams}`;
  const forceRegister = getSearchParameter('registration') === '1';
  const forceLogin = getSearchParameter('login') === '1';

  // [TEST] remove these 2 values, if things should REALLY be visible to the end user
  const showRegisterViaCvSection = getSearchParameter('cv_upload') === '1';
  const enableEmailOnly = getSearchParameter('email_only') === '1';

  useEffect(() => {
    trackPageView({
      page: 'signup',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (forceRegister || typeof window === 'undefined') {
      return;
    }

    if (forceLogin || isRecurringUser) {
      window.location.href = loginUrl;
    }
  }, [forceRegister, forceLogin, isRecurringUser, loginUrl]);

  // we do not want to show a 404 as behaviour
  // xinglet is available logged out but if user is Logged in, it gets redirected.
  if (isLoggedIn) {
    const url = getMainHost(host.getHostname());
    redirectTo(url + '/jobs/find');

    return null;
  }

  return (
    <BackgroundLayout>
      <RegistrationForm
        signupChannel={signupChannel}
        emailOnly={enableEmailOnly}
        neededFields={enableEmailOnly ? ['email', 'password'] : undefined}
        cvUploadConfig={
          showRegisterViaCvSection
            ? {
                //using entity pages urn for testing purposes
                source: 'surn:x-xing:entitypages:page:244471.44c800',
                flowIdentifier: 'NormalRegistrationFlow',
              }
            : undefined
        }
        enableAppleSignup
        enableGoogleSignup
      />
    </BackgroundLayout>
  );
};
